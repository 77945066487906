import React, { useState } from "react"
import { Link, graphql} from "gatsby"
import Img from "gatsby-image"
import {
  FacebookShareButton,
  FacebookIcon,
  EmailShareButton,
  LinkedinShareButton,
  LinkedinIcon,
  WhatsappShareButton,
  WhatsappIcon,
  TwitterShareButton,
  TwitterIcon,
  EmailIcon,
} from "react-share"

import Header from "../components/header"
import Footer from "../components/footer"
import SEO from "../components/seo"

import "../components/layout.css"
import "../styles/modal.sass"
import "../styles/modal.css"

const Post = ({data}) =>{

  const post= data.wordpressPost
  const slug = post.slug
  const POST_ID = data.wordpressPost.wordpress_id
  const [comment,commentStatus] = useState(" ")

  const shareUrl = 'https://liveabsolute.org/'+ slug

  const [modal,moddisp] = useState("modal")

  const handleSubmit = (evt) => {
    evt.preventDefault()

    const [postId, name, email, comment] = evt.target.elements

    const data = JSON.stringify({
      post: postId.value,
      author_name: name.value,
      author_email: email.value,
      content: comment.value,
    })

    fetch("https://blogend.liveabsolute.org/wp/wp-json/wp/v2/comments", {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
      },
      body: data,
    })
      .then((response) => {
        if (response.ok === true) {
          // Submitted successfully!
          commentStatus ("Comment sent") 
        }
        else {
          commentStatus ("Oh no try again") 
        }
  
        return response.json()
      })
      .then((object) => {
        // Comment submission failed.
        // Output `object.message` to see the error message.
      })
      .catch(error => console.error('Error:', error))
  }

return(
    <div className="font">
      <Header theme="navbar" />
      <SEO title={post.title} description={post.excerpt} image={(post.featured_media === null)? " " : post.featured_media.localFile.childImageSharp.fluid.src }/>
      <div className={(post.tags === null) ? "post" : (post.tags[0].name == "malayalam" )? "post maltitle": "post" }>   
          <Img fluid={(post.featured_media === null)? " ": post.featured_media.localFile.childImageSharp.fluid } />
          <h1>{post.title}</h1>
          <p style={{
            textAlign: 'center',
          }}>{post.categories[0].name} | {post.author.name} | {post.date}</p>
          <div  dangerouslySetInnerHTML={{__html: post.content}}></div>

          <div className="postpanel">
            <div className="social">
              <div>
                <FacebookShareButton title="facebook" url={shareUrl}>
                  <FacebookIcon size={50} />
                </FacebookShareButton>
              </div>
              <div>
                <TwitterShareButton title="Live Absolute – Scribbling from Absoluters: Help to achieve the best outcome to themselves just by tune according to their Preferences OR Life Scripts." url={shareUrl}>
                  <TwitterIcon size={50} />
                </TwitterShareButton>
              </div>
              <div>
                <LinkedinShareButton title="Live Absolute – Scribbling from Absoluters: Help to achieve the best outcome to themselves just by tune according to their Preferences OR Life Scripts." url={shareUrl}>
                  <LinkedinIcon size={50} />
                </LinkedinShareButton>
              </div>
              <div>
                <WhatsappShareButton title="Live Absolute – Scribbling from Absoluters: Help to achieve the best outcome to themselves just by tune according to their Preferences OR Life Scripts." url={shareUrl}>
                  <WhatsappIcon size={50} />
                </WhatsappShareButton>
              </div>
              <div>
                <EmailShareButton title="Live Absolute – Scribbling from Absoluters: Help to achieve the best outcome to themselves just by tune according to their Preferences OR Life Scripts." url={shareUrl}>
                  <EmailIcon size={50} />
                </EmailShareButton>
              </div>
              <div>
                <button className="button" onClick= {() => {
                  moddisp((modal === "modal")? "modal moddisp" : "modal" )
                }} id="myBtn">Comment</button>

                <div id="myModal" className={modal}>
                    <div className="modal-content">
                      <div className="modal-header">
                        <h2>Comments</h2>
                        <div className="close" onClick={() => {
                          moddisp((modal === "modal moddisp")? "modal" : "modal moddisp" )
                        }}>&times;</div>
                      </div>
                      <div className="modal-body">
                        <form onSubmit={handleSubmit} className="form" method="post">
                          <input type="hidden" id="postId" value={POST_ID} />
                          {/* name */}
                          <div className="input"> 
                            <label htmlFor="name">Name*</label>
                            <input type="text" id="name" required  ></input>
                          </div>
                          {/* email */}
                          <div className="input">
                            <label htmlFor="email">Email*</label>
                            <input type="email" id="email" required></input>
                          </div>
                          {/* comments */}
                          <div className="input">
                            <label htmlFor="comment" >Comments*</label>
                            <textarea id="comment" required ></textarea>
                          </div>
                          {/* submit */}
                          <p style = {{
                            color: "green"
                          }}>{comment}</p>
                          <input className="submit" type="submit" value="Post comment!"></input>
                        </form>
                      </div>
                    </div>
                  </div>
              </div>
            </div>
            <div className="reverse" style={{ 
                        textAlign: 'right',
                      }}>
            <a style={{
              color: 'white',
              fontSize: '1.7em',
              textDecoration: 'none',
            }} href="javascript:history.back()">Go Back</a>
            </div>
          </div>
        </div>
      <Footer theme="footer"/>
    </div>
)
}

export default Post

export const postQuery = graphql`
    query($id: String!) {
      wordpressPost(id: {eq: $id}) {
        title
        wordpress_id
        excerpt
        content
        tags {
          name
        }
        date(formatString: "DD/MM/YYYY")
        slug
        featured_media {
          localFile {
            childImageSharp {
              fluid(maxWidth: 1000) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
        categories {
          id
          name
        }
        author {
          id
          name
        }
      }
      site {
        siteMetadata {
          twitterHandle
          url
        }
      }
      black: file(relativePath: {eq: "dribble first-01.jpg"}) {
        childImageSharp {
          fluid (maxWidth:1000) {
            ...GatsbyImageSharpFluid_tracedSVG
          }
        }
      }
    }    
    `